<template>
  <div class="bg-bg_alt_color p-2 rounded shadow">
    <div class="wrapper">
      <div class="field">
        <AuthTextField
          v-model="form.first_name"
          :label="t('first_name')"
          type="text"
          error="profile-first_name"
        />
      </div>

      <div class="field">
        <AuthTextField
          v-model="form.last_name"
          :label="t('last_name')"
          type="text"
          error="profile-last_name"
        />
      </div>
    </div>

    <div class="wrapper">
      <div class="field">
        <!-- Email -->
        <AuthTextField
          v-model="form.email"
          :label="t('email')"
          type="email"
          error="profile-email"
        />

        <!-- Verify Email -->
        <div v-if="session?.email && !session.email_verified">
          <MevcutResponseError class="my-2" api-key="verify_email" />
          <button
            class="text-nav_color cursor-pointer mb-5"
            :disabled="emailForm.sending"
            @click="resendHandler"
          >
            {{ t('verify_email') }}
          </button>
        </div>
      </div>

      <div class="field">
        <!-- Phone -->
        <AuthPhoneField
          id="profile-phone-input"
          v-model="form.phone"
          error="profile-phone"
        />

        <!-- Verify Phone -->
        <RikazModalDialog
          v-if="session?.phone && !session?.phone_verified"
          overlay="bg-black opacity-95"
          :closable="false"
        >
          <template #trigger="{ open }">
            <div class="text-nav_color cursor-pointer mb-5" @click="open">
              {{ t('verify_phone') }}
            </div>
          </template>

          <template #content="{ close }">
            <AuthPhoneVerification @close="close" />
          </template>
        </RikazModalDialog>
      </div>
    </div>

    <!-- Date Of Birth -->
    <!-- <MevcutDatePicker v-model="props.form.brith_date" /> -->

    <div class="wrapper">
      <div class="field">
        <div class="h-2"></div>
        <!-- Current Password -->
        <AuthTextField
          v-model="form.old_password"
          :label="t('current_password')"
          placeholder="********"
          type="password"
          autocomplete="new-password"
          error="profile-old_password"
        />

        <!-- New Password -->
        <AuthTextField
          v-model="form.password"
          :label="t('new_password')"
          placeholder="********"
          type="password"
          autocomplete="new-password"
          error="profile-password"
        />

        <!-- Password Confirmation -->
        <AuthTextField
          v-model="form.password_confirmation"
          :label="t('password_confirmation')"
          placeholder="********"
          type="password"
          autocomplete="new-password"
          error="profile-password_confirmation"
        />
      </div>

      <div class="field pt-2">
        <span
          class="text-sm font-semibold text-mevcut-light"
        >
          {{ t('city') }}
        </span>
        <MyAccountPlacesCitiesDropDown
          v-model="form.city_id"
          padding="p-[6px]"
          class="mt-2 mb-10"
        />
        <MyAccountProfileCropper @cropped="form.profile_image = $event.image" />
      </div>
    </div>

    <!-- Submit -->
    <MevcutResponseError
      class="my-2"
      api-key="profile"
      :except="[
        'profile-first_name',
        'profile-last_name',
        'profile-email',
        'profile-phone',
        'profile-old_password',
        'profile-password',
        'profile-password_confirmation'
      ]"
    />
    <MevcutSubmitButton
      :title="t('save')"
      class="my-2"
      :loading="form.loading"
      @submit="updateHandler"
    />
  </div>
</template>

<script setup lang="ts">
const { session, form, updateHandler } = useProfile()
const { form: emailForm, resendHandler } = useVerifyEmail()
const { t } = useI18n()

</script>

  <style scoped>
  .wrapper {
    @apply flex flex-wrap;
  }

  .field {
    @apply px-1 flex-1 min-w-[15rem];
  }
  </style>
